export class DadosGerais {
  public campanhas: CampanhaGerais[];
  public metodologias: Metodologia[];
  public escolaridades: Escolaridade[];
  public skus: SKU[];
  public locaisCampanha: LocalCampanha[];
}

export class CampanhaGerais {
  public idCampanha: number;
  public idCampanhaQrCode: number;
  public descricaoCampanha: string;
  public idCampanhaQRCode: string;
  public inicio: Date;
  public termino: Date;
  public canal: string;
  public urlRedirectQrCode: string;
  public marcaCampanha: string;
  public ufCampanha: string;
  public regionalCampanha: string;
  public nucleoHUbCampanha: string;
  public escola: string;
  public cidade: string;
  public marcaCampanhaUsuario: string;
  public ufCampanhaUsuario: string;
  public regionalCampanhaUsuario: string;
  public nucleoHUbCampanhaUsuario: string;
  public leadsRegistrados: number;
  public leadsNaoEnviados: number;
}

export class Metodologia {
  public chave: number;
  public nomeMetodologia: string;
}

export class Escolaridade {
  public chave: number;
  public nomeEscolaridade: string;
}

export class Curso {
  public chave: number;
  public tipoCurso: string;
  public nomeCurso: string;
}
export class SKU {
  public chave: number;
  public marca: string;
  public siglaRegional: string;
  public uf: string;
  public codigoMunicipio: string;
  public municipio: string;
  public codCampus: string;
  public nomeCampus: string;
  public tipoPolo: string;
  public codCurso: string;
  public nomeCurso: string;
  public codTurno: string;
  public nomeTurno: string;
  public modalidade: string;
  public indModalidade: string;
  public codTipoGraduacao: string;
  public tipoGraduacao: string;
  public grauAcademico: string;
  public codIes: string;
  public nomeIes: string;
  public idCampanha: number;
}
export class LocalCampanha {
  public chave: number;
  public idCampanha: number;
  public uf: string;
  public cidade: string;
  public bairro: string;
  public localCaptura: string;
}
